import en from "./en.json";
import zh from "./zh.json";
import fra from "./fra.json"
import spa from "./spa.json"
import pt from "./pt.json"
import ara from "./ara.json"
import ru from "./ru.json"
import per from "./per.json"
import hi from "./hi.json"
export default {
  en,
  zh,
  fra,
  spa,
  pt,
  ara,
  ru,
  per,
  hi
};
