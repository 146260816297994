import VueRouter from "vue-router";

// const Index = () => import("@/pages/index/index.vue");
// import Test from "@/pages/test/index.vue";
// import HelloWorld from "@/components/HelloWorld.vue";
const ShoppingCart = () => import(/* webpackPrefetch: true */ "@/pages/shopping-cart/index.vue");
const CheckoutCounter = () => import(/* webpackPrefetch: true */ "@/pages/checkout/counter.vue");
const CheckoutCounterPlus = () => import(/* webpackPrefetch: true */ "@/pages/checkout/counter_plus.vue");
const CheckoutPayment = () => import(/* webpackPrefetch: true */ "@/pages/checkout/payment.vue");
const MerchantSettlement = () => import(/* webpackPrefetch: true */ "@/pages/merchant-settlement");
const OrderIndex = () => import(/* webpackPrefetch: true */ "@/pages/order");
const Login = () => import(/* webpackPrefetch: true */ "@/pages/user/login");
const GuaranteeIndex = () => import(/* webpackPrefetch: true */ "@/pages/shortcut/guarantee.vue");
const PaymentIndex = () => import(/* webpackPrefetch: true */ "@/pages/shortcut/payment.vue");
const DeliveryIndex = () => import(/* webpackPrefetch: true */ "@/pages/shortcut/delivery.vue");
const termsConditionsIndex = () =>
    import(/* webpackPrefetch: true */ "@/pages/merchant-settlement/termsConditions.vue");
const sellerPolicyIndex = () =>
    import(/* webpackPrefetch: true */ "@/pages/merchant-settlement/sellerPolicy.vue");
const buyerPolicyIndex = () =>
    import(/* webpackPrefetch: true */ "@/pages/merchant-settlement/buyerPolicy.vue");
const privacyPolicyIndex = () =>
    import(/* webpackPrefetch: true */ "@/pages/merchant-settlement/privacyPolicy.vue");
const shippingRefundIndex = () =>
    import(/* webpackPrefetch: true */ "@/pages/merchant-settlement/shippingRefund.vue");
const wholesalePolicyIndex = () =>
    import(/* webpackPrefetch: true */ "@/pages/merchant-settlement/wholesalePolicy.vue");
const personalCenterIndex = () => import(/* webpackPrefetch: true */ "@/pages/personal-center/index.vue");

// 解决已在此路由页面，再次点击此路由页面出现报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

export const routes = [
    {
        path: "/v2/",
        name: "test",
        component: () => import("@/layout"),
        redirect: "index",
        meta: {
            title: "Sokogate",
        },
        // children: [
        //   {
        //     path: "/v2/",
        //     name: "Test",
        //     component: Test,
        //   },
        // ],
    },
    {
        path: "/",
        name: "index",
        component: () => import("@/layout"),
        redirect: "index",
        children: [
            {
                path: "/",
                name: "yrdy",
                component: () => import("@/pages/product/home.vue"),
            },
        ],
    },
    // app端flutterwave支付
    {
        path: "/v2/flutterwave",
        name: "flutterwave",
        component: () => import("@/components/payment/Appflutterwave")
    },
    {
        path: "/v2/login",
        name: "login",
        component: Login,
    },
    // privacy Policy
    {
        path: "/v2/privacy-policy",
        name: "privacy Policy",
        component: privacyPolicyIndex,
    },
    {
        path: "/v2/forgetPassword",
        name: "forgetPassword",
        component: () => import("@/pages/user/forgetPassword.vue"),
    },
    {
        path: "/v2/register",
        name: "register",
        component: () => import("@/pages/user/register.vue"),
    },
    {
        path: "/v2/",
        name: "layout",
        component: () => import("@/layout"),
        redirect: "index",
        children: [
            {
                path: "/merchant-settlement",
                name: "merchant_settlement",
                component: MerchantSettlement,
            },
            {
                path: "/v2/trade-assurance",
                name: "Trade Assurance",
                component: () =>
                    import("@/pages/merchant-settlement/tradeAssurance.vue"),
            },
            {
                path: "/v2/order",
                name: "order_index",
                component: OrderIndex,
            },
            // guarantee
            {
                path: "/v2/guarantee",
                name: "guarantee",
                component: GuaranteeIndex,
            },
            // payment
            {
                path: "/v2/payment",
                name: "v2payment",
                component: PaymentIndex,
            },
            // delivery
            {
                path: "/v2/delivery",
                name: "delivery",
                component: DeliveryIndex,
            },
            // terms Conditions
            {
                path: "/v2/terms-conditions",
                name: "terms Conditions",
                component: termsConditionsIndex,
            },
            // seller Policy
            {
                path: "/v2/seller-policy",
                name: "seller Policy",
                component: sellerPolicyIndex,
            },
            // buyer Policy
            {
                path: "/v2/buyer-policy",
                name: "buyer Policy",
                component: buyerPolicyIndex,
            },

            // shipping & Refund
            {
                path: "/v2/shipping-refund",
                name: "shipping & Refund",
                component: shippingRefundIndex,
            },
            // wholesale Policy
            {
                path: "/v2/wholesale-policy",
                name: "wholesale Policy",
                component: wholesalePolicyIndex,
            },
            // 个人中心
            {
                path: "/v2/personal-center",
                name: "Personal Center",
                component: personalCenterIndex,
            },
            // 个人中心中的地址管理
            {
                path: "/v2/personal-center/address-management",
                name: "Address management",
                component: () => import("@/pages/personal-center/address.vue"),
            },
            // 个人中心中的物流公司管理
            {
                path: "/v2/personal-center/logistics-management",
                name: "Logistics management",
                component: () => import("@/pages/personal-center/logistics.vue"),
            },
            // 个人中心中的账户设置
            {
                path: "/v2/personal-center/account",
                name: "ACCOUNT SETTINGS",
                component: () => import("@/pages/personal-center/account.vue"),
            },
            // 个人中心中的退款管理
            {
                path: "/v2/personal-center/coupon",
                name: "MY COUPON",
                component: () => import("@/pages/personal-center/coupon.vue"),
            },
            // 个人中心中的查询管理
            {
                path: "/v2/personal-center/inquiry",
                name: "MY INQUIRY",
                component: () => import("@/pages/personal-center/inquiry.vue"),
            },
            // 个人中心中的评价管理
            {
                path: "/v2/personal-center/evaluation",
                name: "EVALUATION MANAGEMENT",
                component: () => import("@/pages/personal-center/evaluation.vue"),
            },
            // 个人中心中的退款管理
            {
                path: "/v2/personal-center/refund",
                name: "Refund management",
                component: () => import("@/pages/personal-center/refund.vue"),
            },
            // 商品详情
            {
                path: "/v2/order/detail",
                name: "OrderDetail",
                component: () => import("@/pages/order/detail.vue"),
            },
            // 商品的分类列表
            {
                path: "/v2/product/list",
                name: "cetegort",
                component: () => import("@/pages/product/cetegortList.vue"),
                meta: {
                    keepAlive: true,
                },
            },
            // 商品分类的详情
            {
                path: "/v2/product/detail",
                name: "Product details",
                component: () => import("@/pages/product/detail.vue"),
            },
            // 产品的主页
            // {
            //   path: "/home",
            //   name: "Product Home",
            //   component: () => import("@/pages/product/home.vue"),
            //   meta: {
            //     keepAlive: true,
            //   },
            // },
            // 店铺详情
            {
                path: "/v2/store/collections",
                name: "Collections",
                component: () => import("@/pages/store/collections"),
            },
            {
                path: "/v2/store/storeList",
                name: "StoreList",
                component: () => import("@/pages/store/storeList"),
            },
            // 店铺的关于我们
            {
                path: "/v2/store/about",
                name: "About",
                component: () => import("@/pages/store/about"),
            },
            // 收藏列表
            {
                path: "/v2/collection/collection",
                name: "Collection",
                component: () => import("@/pages/collection/collection"),
            },
            // 手机号实名认证
            {
                path: "/v2/authentication/mobileAuthentication",
                name: "mobileAuthentication",
                component: () => import("@/pages/authentication/mobileAuthentication"),
            },
            //身份证实名认证
            {
                path: "/v2/authentication/idcardAuthentication",
                name: "idcardAuthentication",
                component: () => import("@/pages/authentication/IdcardAuthentication"),
            },
            // 实名认证审核中
            {
                path: "/v2/authentication/waitingapproval",
                name: "waitingapproval",
                component: () => import("@/pages/authentication/waitingapproval"),
            },
            // 实名认证完成
            {
                path: "/v2/authentication/auditcompleted",
                name: "auditcompleted",
                component: () => import("@/pages/authentication/auditcompleted"),
            },
            // 关于我们
            {
                path: "/v2/aboutus",
                name: "aboutus",
                component: () => import("@/pages/personal-center/aboutus")
            },
        ],
    },
    {
        path: "/v2/",
        name: "streamline",
        component: () => import("@/layout/streamline/index"),
        redirect: "index",
        children: [
            {
                path: "/v2/shopping-cart",
                name: "shoppingCart",
                component: ShoppingCart,
            },
            {
                path: "/v2/checkout/counter_plus",
                name: "checkoutCounterPlus",
                component: CheckoutCounterPlus,
            },
            {
                path: "/v2/checkout/counter",
                name: "checkoutCounter",
                component: CheckoutCounter,
            },
            {
                path: "/v2/checkout/payment",
                name: "payment",
                component: CheckoutPayment,
            },
        ],
    },
    { path: "*", redirect: "/" },
];
